import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide51/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide51/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide51/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide51/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide51/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
     5 mejores formas de usar un sofware omnicanal
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La era digital ha transformado radicalmente la forma en que las empresas
      interactúan con sus clientes. La omnicanalidad, una estrategia que permite
      ofrecer una experiencia de cliente coherente y fluida a través de
      múltiples canales de comunicación, se ha convertido en una necesidad.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    En este contexto, un software omnicanal desempeñan un papel crucial,
    permitiendo a las organizaciones gestionar de forma eficiente y efectiva sus
    puntos de contacto con los clientes.
    <br />
    <br />
    Esto no solo mejora la satisfacción del cliente, sino que también aumenta la
    lealtad hacia la marca, ya que los consumidores valoran las interacciones
    fluidas y sin fricciones independientemente del medio que elijan para
    comunicarse.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al Cliente ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Autoservicio en canal telefónico y digital.",
      },
      {
        key: 2,
        text: "Automatizar tareas con inteligencia artificial.",
      },
      {
        key: 3,
        text: "Personalizar la atención al cliente.",
      },
      {
        key: 4,
        text: "Integración de canales con herramientas externas.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
